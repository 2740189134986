<template>
  <div class="wrap">
    <img src="../../../assets/imgs/explorebackground.jpg" class="image" alt="exploreBackground" />
    <div class="box">
      <div class="title">{{ $t("index.为探索而生") }}</div>
      <ul class="smalltitle">
        <li>
          <p>
            <img src="../../../assets/imgs/ForExplore01.png" alt="RetailCenter" />
          </p>
          <p class="headline">{{ $t("index.零售中心") }}</p>
          <p class="content">
            {{ $t("index.找到距离您最近的格物销售和服务中心") }}
          </p>
        </li>
        <li>
          <p>
            <img src="../../../assets/imgs/ForExplore02.png" alt="Application"/>
          </p>
          <p class="headline">{{ $t("index.金融计算器") }}</p>
          <p class="content">{{ $t("index.多种贷款方式，总有一款适合您") }}</p>
        </li>
        <li>
          <p>
            <img src="../../../assets/imgs/ForExplore03.png"  alt="Parameters"/>
          </p>
          <p class="headline">{{ $t("index.参数配置") }}</p>
          <p class="content">
            {{ $t("index.查看参数配置，选择更适合您的摩托车") }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang='scss' scoped>
.wrap {
  position: relative;
  width: 100vw;
  background-color: rgb(250, 239, 233);
  
}
img {
  vertical-align: top;
}
.image {
  width: 100vw;
  height: 45vw;
}
.box {
  position: absolute;
  top: 0;
  width: 100vw;
  text-align: center;
  .title {
    font-family: PingFang-SC-Medium;
    font-size: 40px;
    line-height: 200px;
  }
  .smalltitle {
    width: 100%;
    justify-content: center;
    display: flex;
    li {
      margin: 0 2vw;
      font-size: 18px;
      p {
        text-align: left;
        width: 26vw;
        // height: 300px;
        img {
          width: 100%;
        }
      }
      .headline {
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        font-size: 18px;
        height: 25px;
        margin-top: 5px;
      }
      .content {
        font-family: PingFang-SC-Regular;
        font-weight: regular;
        font-size: 16px;
      }
    }

    li:hover {
      cursor: pointer;
    }
  }
}
</style>